import { render, staticRenderFns } from "./InformacoesGeraisLista.vue?vue&type=template&id=35b275b0"
import script from "./InformacoesGeraisLista.vue?vue&type=script&lang=js"
export * from "./InformacoesGeraisLista.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VDataTable,VIcon,VProgressCircular})
